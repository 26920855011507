<template>
    <section class="buscador -cartas -section">
        <div class="container">


            <div class="filtro">

                <div class="categories row-center">
                    <a :active="true">Cartas de servicios</a>

                </div>


                <div class="inputs row-start" v-if="!!filter">

                    <div v-if="!checkroutepublico()" class="contenedor-input -three">
                        <input type="text" placeholder="Buscar..." v-model="filter.texto" @keyup.enter="$emit('search')">
                    </div>
                    <div v-else class="contenedor-input -full">
                        <input type="text" placeholder="Buscar..." v-model="filter.texto" @keyup.enter="$emit('search')">
                    </div>


                    <div class="contenedor-input -three" v-if="dominios.areas_accion && !checkroutepublico()">

                        <multiselect v-model="filter.lists.areas_accion" :options="dominios.areas_accion"
                            placeholder="Áreas de Acción" label="nombre" :multiple="true" :closeOnSelect="false"
                            :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                        </multiselect>

                    </div>

                    <div class="contenedor-input -three" v-if="dominios.areas_gobierno && !checkroutepublico()">

                        <multiselect v-model="filter.lists.areas_gobierno" :options="dominios.areas_gobierno"
                            placeholder="Áreas de Gobierno, Distritos..." label="nombre" :multiple="true"
                            :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                            track-by="nombre">
                        </multiselect>

                    </div>


                    <div class="tags row-start">

                        <template v-for="(el, key) in filter.lists">
                            <span class="tag" v-for="(data, tagkey) in el" :key="tagkey + key">
                                {{ data.nombre }}
                                <a @click="remove(key, data)">✕</a>
                            </span>
                        </template>
                        <span class="tag" v-if="filter.texto">
                            {{ filter.texto }}
                            <a @click="filter.texto = ''">✕</a>
                        </span>


                        <a @click="reset" class="erase" v-if="showerase">Borrar filtros</a>

                    </div>

                    <div class="contenedor-botones">
                        <a class="btn -search" @click="setBusqueda">Buscar</a>
                    </div>

                </div>


            </div>

        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import router from '@/router';
import { mapActions } from 'vuex';

export default {
    name: "buscador",
    mounted() {
        if (window.sessionStorage.getItem('buscador')) {
            this.filter = JSON.parse(window.sessionStorage.getItem('buscador'));
            window.sessionStorage.removeItem('buscador');
            this.$emit('input', this.filter);
        } else {
            this.$emit('input', this.filter);
        }

        this.requestAreasAccion();
        this.requestAreasGobierno();
    },
    data: () => ({
        filter: {
            lists: {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: []
            },
            texto: '',
            type: "actuaciones",
            tipoindicador: ''
        },
        cartas: [
            {
                "id": "Pe",
                "label": "Indicador Plan Estratégico"
            },
            {
                "id": "Pog",
                "label": "Indicador Plan Operativo"
            },
            {
                "id": "Carta",
                "label": "Indicador Cartas de Servicios"
            },

        ]
    }),
    methods: {
        remove(where, value) {
            this.filter.lists[where] = this.filter.lists[where].filter(e => e.id != value.id);
        },
        setBusqueda() {
            if (this.tobuscador) {
                this.setToBuscador()
            } else {
                this.$emit('search')
            }
        },
        setToBuscador() {
            window.sessionStorage.setItem('buscador', JSON.stringify(this.filter));
            router.push({ name: 'buscador' })
        },
        reset() {
            this.filter.lists = {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: []
            };
            this.filter.texto = '';
            this.filter.tipoindicado = '';

            setTimeout(() => {
                this.setBusqueda();
            })
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        },
        ...mapActions(
            ['requestAreasAccion', 
            'requestAreasGobierno'
        ]),
    },
    computed: {
        ...mapGetters({
            dominios: 'getDominios'
        }),
        tobuscador() {
            let routes = ['Home'];
            return (routes.some(e => e == this.$route.name));
        },
        showerase() {
            let show = false;
            (this.filter.texto) ? show = true : null;
            for (let element in this.filter.lists) {
                (this.filter.lists[element].length != 0) ? show = true : null;
            }

            return show;
        }
    },
    watch: {
        'filter': {
            deep: true,
            handler() {
                this.$emit('input', this.filter);
            }
        },
        'filter.type'() {
            this.filter.lists = {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: []
            };
            this.filter.texto = '';
            this.filter.tipoindicador = '';
        }
    }

}
</script>