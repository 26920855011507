<template>
    <div class="wrapper">

        <section class="encabezado -section" data="cartas-servicios">
            <h2 class="title -white">Cartas de servicios</h2>
            <h3 class="subtitle container">
                ¿Cuáles son nuestros compromisos de calidad?
            </h3>
        </section>


        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link v-if="!checkroutepublico()" to="/">Inicio</router-link>
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.listadocartasservicios')">Sistema de cartas de
                    servicios</router-link>
                <router-link :to="$route.fullPath">Cartas de servicios</router-link>
            </div>
        </nav>

        <a v-if="!checkroutepublico()" @click="back" class="back">Volver</a>

        <buscador v-model="filter"></buscador>

        <section class="contenedor-cartas container -section" v-if="dominios.cartas">
            <carta v-for="(data, key) in cartasfiltered" :data="data" :key="key"></carta>
        </section>

        <banner-ayudamos :data="['que-son-cartas-servicios', 'cartas-vigentes', 'calidad']"></banner-ayudamos>

    </div>
</template>


<script>

import carta from '@/components/parts/carta-de-servicio';

import buscador from '@/components/parts/buscador-cartas';

import { mapGetters } from 'vuex'

import router from '@/router';

export default {
    name: 'listado-cartas-servicios',
    data: () => ({
        filter: {
            lists: {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
                status: []
            },
            texto: '',
        }
    }),
    computed: {
        ...mapGetters({
            dominios: 'getDominios',
        }),
        cartasfiltered() {
            let cartas = JSON.parse(JSON.stringify(this.dominios.cartas));

            //NOMBRES
            if (this.filter.texto) {
                let searchIn = ['carta', 'areadeaccion', 'areagobierno', 'unidadresponsable'];
                cartas = cartas.filter(actuacion => {
                    let has = false;
                    searchIn.forEach(key => {
                        if (actuacion[key]) {
                            if (actuacion[key].toLowerCase().includes(this.filter.texto.toLowerCase())) {
                                has = true;
                            }
                        }
                    })
                    return has;
                })
            }

            //ÁREAS ACCION
            if (this.filter.lists.areas_accion.length != 0) {
                let areas_accion = this.filter.lists.areas_accion.map(e => e.id);
                cartas = cartas.filter(actuacion => {
                    return (areas_accion.some(area => (area == actuacion.areadeaccionid)));
                })
            }

            //AREAS GOBIERNO
            // if (this.filter.lists.areas_gobierno.length != 0) {
            //     let areas_gobierno = this.filter.lists.areas_gobierno.map(e => e.id);
            //     cartas = cartas.filter(actuacion => {
            //         return (areas_gobierno.some(area => (area == actuacion.areagobiernoid)));
            //     })
            // }

            //AREAS GOBIERNO
            if (this.filter.lists.areas_gobierno.length != 0) {
                let areas_gobierno = this.filter.lists.areas_gobierno.map(e => e.id);
                cartas = cartas.filter(actuacion => {
                    // Verificamos que areagobiernoid no sea null o undefined
                    if (actuacion.areagobiernoid && typeof actuacion.areagobiernoid === 'string') {
                        // Convertimos el string de areagobiernoid en un array de números
                        let idsArray = actuacion.areagobiernoid.split(';').map(id => id.trim());

                        // Filtramos las cartas verificando si algún ID de areas_gobierno coincide
                        return idsArray.some(id => areas_gobierno.includes(Number(id)));
                    } else {
                        return false;
                    }
                });
            }

            return cartas;
        }
    },
    methods: {
        back() {
            router.go(-1);
        },
        checkroutepublico() {
            if (this.$route.name == 'listado-cartas-servicios-publico') {
                return true;
            } else {
                return false;
            }
            // if (this.$route.path.includes('publico')) {
            //     return true;
            // } else {
            //     return false;
            // }
        }
    },
    components: {
        'carta': carta,
        'buscador': buscador
    }

}
</script>